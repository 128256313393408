<template>
  <div id="app" class="app">
    <HomePage/>
  </div>
</template>

<script>
import HomePage from "@/components/HomePage.vue";

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>