<template>
  <div class="home">
    <el-container style="margin: 0;">
      <el-header class="navbar" height="80px">
        <el-image class="logo" :src="logo"/>
        <el-menu
            :default-active="activeMenu"
            class="menu"
            mode="horizontal"
            @select="handleTabSelect"
            background-color="#2c3e50"
            text-color="#EDEDED"
            active-text-color="#EA5F3C">
          <el-menu-item class="menu-item" index="1">首页</el-menu-item>
          <el-menu-item class="menu-item" index="2">找方案</el-menu-item>
          <el-menu-item class="menu-item" index="3">找供应商</el-menu-item>
          <el-menu-item class="menu-item" index="4">找技术</el-menu-item>
          <el-menu-item class="menu-item" index="5">找店铺</el-menu-item>
          <el-menu-item class="menu-item" index="6">学习资料</el-menu-item>
        </el-menu>
      </el-header>

      <el-main>
        <div v-if="activeMenu === '1'" class="content">
          <p class="title-big">优选方案</p>
          <div class="content-sub">
            <div v-for="item in recommendItems" class="card" :key="item.imgUrl">
              <el-image class="image" :src="item.imgUrl" :fit="'cover'"/>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.desc }}</p>
              <p class="desc-small">{{ item.userName }}｜{{ item.timeDesc }}</p>
            </div>
          </div>
          <p class="title-big">供应商</p>
          <div class="content-sub">
            <div v-for="item in items" class="card" :key="item.imgUrl">
              <el-image class="image" :src="item.imgUrl" :fit="'cover'"/>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.userName }}｜{{ item.timeDesc }}</p>
            </div>
          </div>
          <p class="title-big">供应商</p>
          <div class="content-sub">
            <div v-for="item in items" class="card" :key="item.imgUrl">
              <el-image class="image" :src="item.imgUrl" :fit="'cover'"/>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.userName }}｜{{ item.timeDesc }}</p>
            </div>
          </div>
          <p class="title-big">技术</p>
          <div class="content-sub">
            <div v-for="item in items" class="card" :key="item.imgUrl">
              <el-image class="image" :src="item.imgUrl" :fit="'cover'"/>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.userName }}｜{{ item.timeDesc }}</p>
            </div>
          </div>
          <p class="title-big">店铺</p>
          <div class="content-sub">
            <div v-for="item in items" class="card" :key="item.imgUrl">
              <el-image class="image" :src="item.imgUrl" :fit="'cover'"/>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.userName }}｜{{ item.timeDesc }}</p>
            </div>
          </div>
        </div>
        <div v-if="activeMenu === '2'" class="content">
          找方案
        </div>
        <div v-if="activeMenu === '3'" class="content">
          找供应商
        </div>
        <div v-if="activeMenu === '4'" class="content">
          找技术
        </div>
        <div v-if="activeMenu === '5'" class="content">
          找店铺
        </div>
        <div v-if="activeMenu === '6'" class="content">
          学习资料
        </div>

      </el-main>

      <div class="footer">
        <el-link type="info">上海码擎网络科技有限公司</el-link>
        <el-link type="info" style="margin-left: 5px; margin-right: 5px;">Copyright@2024 findsolu.com 版权所有</el-link>
        <el-link type="info" style="margin-left: 5px; margin-right: 5px;" href="http://beian.miit.gov.cn/">沪ICP备2024097213号</el-link>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: '1',
      logo: require('@/assets/ic_logo.png'),
      recommendItems: [
        {
          imgUrl: 'https://aigctop100.oss-cn-shanghai.aliyuncs.com/findsolu/bg_001.jpeg',
          title: '电商系统',
          desc: '包含电商网站、APP、小程序全套源代码，宝塔面板轻松部署',
          userName: '李雄风',
          timeDesc: '2小时前',
          userAvatar: '',
          favNum: 1008,
          viewNum: 1200
        },
        {
          imgUrl: 'https://aigctop100.oss-cn-shanghai.aliyuncs.com/findsolu/banner_001.png',
          title: '餐饮系统',
          desc: '包含电商网站、APP、点餐小程序、外卖小程序全套源代码，宝塔面板轻松部署',
          userName: '李雄风',
          timeDesc: '2小时前',
          userAvatar: '',
          favNum: 1008,
          viewNum: 1200
        }
      ]
    };
  },
  methods: {
    handleTabSelect(tab) {
      this.activeMenu = tab
      console.log(this.activeMenu)
    }
  }
}
</script>

<style scoped>

.home {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
  justify-content: left;
  text-align: center;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #2c3e50;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 100;
  padding-left: 80px;
  padding-right: 80px;
}

.logo {
  width: 56px;
  height: 56px;
  border-radius: 5px;
  margin-right: 20px;
}

.menu {
  height: 54px;
}

.menu-item {
  height: 56px;
  line-height: 56px;
  font-size: 17px;
  font-weight: bold;
}

.content {
  width: 100%;
  margin-top: 80px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
}

.title-big {
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.content-sub {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}

.card {
  width: 19.1%;
  min-width: 280px;
  height: auto;
  margin: 10px 0.45% 10px 0.45%;
  border-radius: 8px;
  padding-bottom: 12px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #FAFAFA;
  border: 1px solid #EDEDED;
  color: #000000;
  transition: transform 0.2s ease;
}

.card:hover {
  background-color: #F2F2F2;
  transform: scale(1.02);
}

.card:hover .title {
  color: #EA5F3C;
}

.card:hover .desc {
  color: #EA5F3C;
}

.image {
  width: 100%;
  height: 180px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin: 5px 12px 2px 12px;
  max-lines: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  text-align: left;
  max-height: 3.0em;
  line-height: 1.5em;
}

.desc {
  font-size: 14px;
  margin: 0 12px;
  max-lines: 2;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  text-align: left;
  max-height: 3.0em;
  line-height: 1.5em;
}


.desc-small {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 0px;
  margin-right: 12px;
  max-lines: 2;
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  text-align: left;
  max-height: 3.0em;
  line-height: 1.5em;
}

.footer {
  width: 100%;
  height: 160px;
  background-color: #2c3e50;
  align-content: center;
  box-sizing: border-box;
  color: white;
}

</style>